<template>
  <div id="simple-calendar-app">
    <booking-preview ref="preview" :bookingPreviewActive.sync="bookingPreviewActive" :currentBooking.sync="currentBooking" @updateCurrentBooking="currentBooking = $event" @openEditBookingPopUp="editData"/>

    <booking-form-pop-up :bookingFormPopUpActive.sync="bookingFormPopUpActive" :currentBooking.sync="currentBooking" @updateCurrentBooking="currentBooking = $event" :isEdit="isBookingEdit" :startDate="startDate" :endDate="endDate" :enableBlockBooking.sync="enableBlockBooking"/>

    <booking-confirmation :bookingConfirmationPopUp.sync="bookingConfirmationPopUp" :currentBooking.sync="currentBooking" @updateCurrentBooking="currentBooking = $event" @openPreview="bookingPreviewActive = true" :bookingConfirmationType="bookingConfirmationType"/>
    <div class="vx-card no-scroll-content">
      <!-- enableDragDrop -->
      <!-- @drop-on-date="eventDragged" -->
      <calendar-view

        ref="calendar"
        :displayPeriodUom="calendarView"
        :show-date="showDate"
        :events="calendarEvents"
        :eventTop="windowWidth <= 400 ? '2rem' : '3rem'"
        eventBorderHeight="0px"
        eventContentHeight="1.65rem"
        class="theme-default"
        @click-date="addNewBookingData"
        @click-event="showData"
        >

        <div slot="header" class="mb-4">

          <div class="vx-row no-gutter" id="calendar">

            <!-- Month Name -->
            <div class="vx-col w-1/3 items-center sm:flex hidden">
              <!-- Add new event button -->
              <!-- <vs-button icon-pack="feather" icon="icon-plus" @click="promptAddNewEvent(new Date())">{{ $t("Add")}}</vs-button> -->
              <vs-button icon-pack="feather" icon="icon-plus" @click="addNewBookingData()">{{ $t("Add")}}</vs-button>
            </div>

            <!-- Current Month -->
            <div class="vx-col sm:w-1/3 w-full sm:my-0 my-3 flex sm:justify-end justify-center order-last">
              <div class="flex items-center">
                <feather-icon
                  :icon="$vs.rtl ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
                  @click="updateMonth(-1)"
                  svgClasses="w-5 h-5 m-1"
                  class="cursor-pointer bg-primary text-white rounded-full" />

                <span class="mx-3 text-xl font-medium whitespace-no-wrap">{{ showDate | month }}</span>

                <feather-icon
                  :icon="$vs.rtl ? 'ChevronLeftIcon' : 'ChevronRightIcon'"
                  @click="updateMonth(1)"
                  svgClasses="w-5 h-5 m-1"
                  class="cursor-pointer bg-primary text-white rounded-full" />
              </div>
            </div>

            <div class="vx-col sm:w-1/3 w-full flex justify-center">
              <template v-for="(view, index) in calendarViewTypes">
                <vs-button
                  v-if="calendarView === view.val"
                  :key="String(view.val) + 'filled'"
                  type="filled"
                  class="p-3 md:px-8 md:py-3"
                  :class="{'border-l-0 rounded-l-none': index, 'rounded-r-none': calendarViewTypes.length !== index+1}"
                  @click="calendarView = view.val"
                  >{{ view.label }}</vs-button>
                <vs-button
                  v-else
                  :key="String(view.val) + 'border'"
                  type="border"
                  class="p-3 md:px-8 md:py-3"
                  :class="{'border-l-0 rounded-l-none': index, 'rounded-r-none': calendarViewTypes.length !== index+1}"
                  @click="calendarView = view.val"
                  >{{ view.label }}</vs-button>
              </template>

            </div>
          </div>

          <div class="vx-row sm:flex hidden mt-4">
            <div class="vx-col w-full flex">
              <!-- Labels -->
              <div class="flex flex-wrap sm:justify-start justify-center">
                  <!-- Bookings States Guide -->
                  <div v-for="(state, index) in bookingStates" :key="'state-'+state.name+'-'+index" class="flex items-center mr-4 mb-2">
                      <div class="h-3 w-3 inline-block rounded-full mr-2" :style="{background: state.color}"></div>
                      <vx-tooltip :text="state.description" >
                        <span>{{ state.display_name }}</span>
                      </vx-tooltip>
                  </div>

                  <!-- Bookings Sources Guide -->
                  <div class="flex items-center mr-4 mb-2 mt-5" color="red">
                      <vx-tooltip text="Booking added by Eventful operating team (admins)">
                        <vs-chip class="product-no font-medium truncate mx-1" color="10163A">
                         A| Admin
                        </vs-chip>
                      </vx-tooltip>

                      <vx-tooltip text="Booking added by a customer using Eventful app">
                        <vs-chip class="product-no font-medium truncate mx-1" color="10163A">
                         E| Eventful App
                        </vs-chip>
                      </vx-tooltip>


                      <vx-tooltip text="Booking Added By Agent User (You)">
                        <vs-chip class="product-no font-medium truncate mx-1" color="10163A">
                         Ag| Agent
                        </vs-chip>
                      </vx-tooltip>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </calendar-view>
      <vs-prompt :title="$t('calendar.bookingBlock')" class="export-options" color="danger" @accept="deleteBookingBlock" :accept-text="$t('delete')" :cancel-text="$t('close')" :active.sync="bookingBlockPromptActive">
            <p>{{$t('calendar.doYouWantToRemoveBookingBlock')}}</p>
      </vs-prompt>
    </div>

  </div>
</template>

<script>
import { CalendarView, CalendarViewHeader } from 'vue-simple-calendar'

// import moduleCalendar from '@/store/calendar/moduleCalendar.js'
import moduleDataList from '@/store/data-list/moduleDataList.js'
import BookingFormPopUp from '@/views/q-pages/Booking/BookingFormPopUp'
import BookingConfirmation from '@/views/q-pages/Booking/BookingConfirmation'
require('vue-simple-calendar/static/css/default.css')

import Datepicker from 'vuejs-datepicker'
import { en, ar } from 'vuejs-datepicker/src/locale'
var moment = require('moment');

export default {
  components: {
    CalendarView,
    CalendarViewHeader,
    Datepicker,
    BookingFormPopUp,
    BookingConfirmation
  },
  data () {
    return {
      showDate: new Date(),
      // currentEvent: {originalEvent: {receipt: {}, state: {}}},
      // currentEventEmptyValue: {originalEvent: {receipt: {}, state: {}}}, // used to return the currentEvent to its initial value when needed

      // to be removed
      id: 0,
      title: '',
      startDate: '',
      endDate: '',
      labelLocal: 'none',

      langAr: ar,
      langEn: en,

      url: '',
      calendarView: 'month',

      calendarViewTypes: [
        {
          label: 'Month',
          val: 'month'
        },
        {
          label: 'Week',
          val: 'week'
        }
      ],

      currentBooking: null,
      isBookingEdit: false,

      // sidebar
      addNewBookingSidebar: false,
      newBooking: {},
      isBookingListShow: false,
      isBookingListEdit: false,
      bookingPreviewActive: false,

      // booking form pop up
      bookingFormPopUpActive: false,
      bookingId: null,

      // booking block
      bookingBlockPromptActive: false,

      enableBlockBooking: false,

      bookingConfirmationPopUp: false,
      bookingConfirmationType: 'bookingConfirmation'
    }
  },
  methods: {
    updateMonth (val) {
      this.showDate = this.$refs.calendar.getIncrementedPeriod(val)
      const date = moment(this.showDate).format('yyyy-MM-DD')
      this.getCalendarBookings(date)
    },

    addNewBookingData (date=null) {
      if(date){
        this.startDate = moment(date).format("yyyy-MM-DD"),
        this.endDate = moment(date).format("yyyy-MM-DD")
        this.enableBlockBooking = true;
      }else{
        this.startDate = null;
        this.endDate = null;
      }
      this.currentBooking = {
        start_date: this.startDate,
        end_date: this.endDate,
        receipt: {
          total_price: 0,
          discount: 0,
          percentage_tax: 15,
          tax: 15,
          services_total_price: 0,
          total_price_with_tax: 0,
          paid_amount: 0,
          agent_manual_discount: 0,
          qosoor_discount: 0,
          paid_at_place_amount: 0,
          receipt_id: null
        },
        event_id: null,
        rooms: [],
        option_id: null,
        state_id: null,
        men_count: 0,
        women_count: 0,
        people_count: 0
      }
      this.isBookingEdit = false;
      this.bookingFormPopUpActive = true;
      this.bookingPreviewActive = false;

    },
    showData (data) {
      if(data.cancelation_reason == null)
        data.cancelation_reason = 'unavailable_date';
      this.currentBooking = data.originalEvent ? data.originalEvent : data
      this.addBookingIdQueryParameter(this.currentBooking.id, this.currentBooking.venue_id);
      let clickedState = this.currentBooking.state.name;
      this.bookingFormPopUpActive = false;
      if(this.currentBooking.state_id == 8 || this.currentBooking.state.name == 'booking_check_rejected') { // check if booking block
        this.bookingBlockPromptActive = true
      } else if(this.bookingConfirmationStatesNames && this.bookingConfirmationStatesNames.includes(clickedState)) {
        this.bookingConfirmationType = clickedState == 'booking_check' ? 'bookingCheck' : ( clickedState == 'visit' ? 'bookingVisit' : 'bookingConfirmation');
        this.bookingConfirmationPopUp = true;
      } else {
        this.bookingPreviewActive = true;
      }
    },
    findBookingAndShowData(bookingId) {
        let _this = this;
        this.$store.dispatch('dataList/getBooking', {venueId:this.currentVenueId, bookingId: bookingId})
        .then(function(response){
            let isArray = Array.isArray(response);
            const item = isArray? response[0].data.data:response.data.data;
            _this.showData(item)
        })
        .catch(error => {
            this.$vs.notify({
                color:'danger',
                title: this.$t('error'),
                text: error
            })
        })
    },
    editData (data) {
      this.currentBooking = data
      this.bookingFormPopUpActive = true;
      this.bookingPreviewActive = false;
      this.isBookingEdit = true;
    },
    deleteBookingBlock() {
      this.$store.dispatch('loader/loaderOn')
      this.$store.dispatch('dataList/deleteBookingBlock', this.currentBooking)
      .then(response=>{
          this.$vs.notify({
              color:'success',
              title: this.$t('deleteDone'),
              text: response.success_message || 'تم حذف حجب الحجوزات بنجاح'
          })
        })
        .catch(error => {
          this.$vs.notify({
              color:'danger',
              title: this.$t('error'),
              text: error || 'عذرًا لم نستطع تعديل البينات لوجود خطأ ما، اذا تكرر الخطأ يُرجى التواصل مع الادارة، وشكرًا'
          })
        })
        .finally(()=>{this.$store.dispatch('loader/loaderOff')},this);
    },
    closePopup () {
      this.bookingPreviewActive = false;
      this.bookingFormPopUpActive = false;
    },


    getCalendarBookings(date){
      const venueId = this.currentVenueId
      this.$store.dispatch('dataList/fetchCalendarBookings', {venueId, date})
    }
  },
  created () {
    const venueId = this.currentVenueId
    const date = this.$route.params.date || moment().format('yyyy-MM-DD')

    this.getCalendarBookings(date)

    window.addEventListener('changeLanguage', function(e){
      //this.$store.dispatch('dataList/runLoading'),
      Promise.all([
        this.$store.dispatch('dataList/fetchBookingStatesList'),
        this.$store.dispatch('dataList/fetchbookingOptionsList', venueId),
        this.$store.dispatch('dataList/fetchVenueRoomsList', venueId)
      ]).then(()=>{this.$store.dispatch('loader/loaderOff')});
    }.bind(this));

    this.$store.dispatch('bookingStates/getBookingStatesArrayByArrayName', {arrayName: 'bookingConfirmationStatesAgent'})

    if(this.$router.history.current.query.booking_id) {
        this.findBookingAndShowData(this.$router.history.current.query.booking_id);
    }

  },

  mounted () {
    const daysHeader = document.getElementsByClassName('cv-header-days')[0]
    daysHeader.style.setProperty('direction','ltr','important')
  },

  computed: {
    isLoading () {
      return this.$store.getters.isLoading
    },
    currentVenueId () {
        return this.$store.getters.currentVenueId
    },

    calendarEvents () {
      const events = []
      const venueId = this.currentVenueId;
      if(this.bookings.length){
        this.bookings.forEach(booking => {
          const event = {...booking, venue_id: venueId, startDate: booking.start_date, id: booking.id, booking_id:booking.id, endDate: booking.end_date, title: booking.customer_name, style:`background: ${booking.state.color}` }
          if(event.added_by == "admin"){

            event.title = "A| "+event.title
          }else if(event.added_by == "customer"){
            event.title = "Q| "+event.title
          }else{
            event.title = "Ag| "+event.title
          }
          if(event.state_id == 8) {
            event.title = this.$t('bookingBlock')
          }
          if(booking.state.name != 'booking_check_accepted')
            events.push(event)
        });
      }
      return events
    },
    windowWidth () {
      return this.$store.state.windowWidth
    },
    bookings () {
      return this.$store.state.dataList.bookings
    },
    bookingStates () {
      return this.$store.state.dataList.bookingStates
    },
    bookingConfirmationStates() {
        return this.$store.state.bookingStates.bookingConfirmationStates;
    },
    bookingConfirmationStatesNames() {
        return this.bookingConfirmationStates.map(state => state.name);
    }
  },
  watch: {
    isLoading(newVal){
      if (newVal == true){
        this.$store.dispatch('loader/loaderOff')
      }else {
        this.$store.dispatch('loader/loaderOff')
      }

    }
  }
}
</script>

<style lang="scss">
.cv-header-days{
    direction: ltr !important;
  }

  [dir=rtl] .cv-header-days{
    direction: ltr !important;
  }

  @import "@/assets/scss/vuexy/apps/simple-calendar.scss";

  #app .cv-header-days{
    direction: ltr !important;
  }
</style>
